import {RouteRecordRaw} from 'vue-router';
import {LocalStorage} from "quasar";

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => {
      // @ts-ignore
      // let tenantId = LocalStorage.getItem('tenantInfo')?.id
      // return tenantId === process.env.HAMIANE_TENANT_ID ? import('layouts/MainLayout.vue') : import('layouts/CustomLayout.vue')
      return import('layouts/MainLayout.vue')
    },
    children: [
      {
        name: 'home',
        path: '/',
        component: () => import('pages/Index.vue')
      },
      {name: 'Playground', path: '/Playground', component: () => import('pages/Playground.vue')},
      {name: 'charityProfile', path: '/:name', component: () => import('pages/CharityProfile.vue')},
      {name: 'projectInfo', path: '/challenge/:id', component: () => import('pages/ProjectInfo.vue')},
      {name: 'projectSearchOld', path: '/challenge/search', redirect: '/challenges'},
      {name: 'projectSearch', path: '/challenges', component: () => import('pages/CampaignSearch.vue')},
      {name: 'faq', path: '/faq', component: () => import('pages/FAQ.vue')},
      {name: 'login', path: '/login', component: () => import('pages/Login.vue')},
      {name: 'register', path: '/register', component: () => import('pages/Register.vue')},
      {name: 'profile', path: '/profile', component: () => import('pages/Profile.vue')},
      {name: 'aboutUs', path: '/about-us', component: () => import('pages/AboutUs.vue')},
      {name: 'search', path: '/search/:searchValue', component: () => import('pages/Search.vue')},
      {name: 'searchPage', path: '/search', component: () => import('pages/Search.vue')},
      {name: 'donate', path: '/donate', component: () => import('pages/Donate.vue')},
      {name: 'donateByCharity', path: '/donate/:charityId', component: () => import('pages/Donate.vue')},
      {name: 'donateByProject', path: '/donate/:charityId/:projectId', component: () => import('pages/Donate.vue')},
      {name: 'privacyPolicy', path: '/privacy-policy', component: () => import('pages/PrivacyPolicy.vue')},
      {name: 'changeLog', path: '/changelog', component: () => import('pages/ChangeLog.vue')},
      {name: 'contactUs', path: '/contact-us', component: () => import('pages/Contact.vue')},
      {name: 'catalog', path: '/catalog', component: () => import('pages/Catalog.vue')},
      {name: 'nav', path: '/nav', component: () => import('pages/Nav.vue')},
      {name: 'donationRequest', path: '/donation-request', component: () => import('pages/DonationRequest.vue')},
      {name: 'charityRegistration', path: '/charity-registration', component: () => import('pages/CharityRegistration.vue')},
      {name: 'pricing', path: '/pricing', component: () => import('pages/Pricing.vue')},
      {name: 'terms', path: '/terms', component: () => import('pages/Terms.vue')},
      {name: 'reports', path: 'reports', component: () => import('pages/Reports.vue')},
      {name: 'reportsPage', path: 'reports/Page/:page', component: () => import('pages/Reports.vue')},
      {name: 'report', path: 'report/:id', component: () => import('pages/Report.vue')},
      {name: 'report&title', path: 'report/:id/:title', component: () => import('pages/Report.vue')},

      {name: 'gift', path: '/gift', component: () => import('pages/Gift.vue')},
      {name: 'giftByCharity', path: '/gift/:charityId', component: () => import('pages/Gift.vue')},
      {name: 'giftByProject', path: '/gift/:charityId/:projectId', component: () => import('pages/Gift.vue')},

      {name: 'page', path: 'page/:name', component: () => import('pages/Page.vue')},
      {name: 'fitr', path: '/fitr', component: () => import('pages/Fitr.vue')},
      {name: 'fitrByCharity', path: '/fitr/:charityId', component: () => import('pages/Fitr.vue')},
      {name: 'fitrByProject', path: '/fitr/:charityId/:projectId', component: () => import('pages/Fitr.vue')},
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {name: 'mag', path: '/mag', component: () => import('pages/Mag.vue')},
      {name: 'blog', path: '/blog', component: () => import('pages/Blog.vue')},
      {name: 'blogPage', path: '/blog/page/:page', component: () => import('pages/Blog.vue')},
      {name: 'blogCategory', path: '/blog/category/:category', component: () => import('pages/Blog.vue')},
      {name: 'post', path: '/blog/:id', component: () => import('pages/Post.vue')},
      {name: 'post&title', path: 'blog/:id/:title', component: () => import('pages/Post.vue')},
    ]
  },
  {
    path: '/',
    component: () => import('layouts/CleanLayout.vue'),
    children: [
      {name: 'chargeAccount', path: '/profile/charge', component: () => import('pages/ChargeAccount.vue')},
      {name: 'payment', path: 'payment/:code/:hash/:charityId', component: () => import('pages/Payment.vue')},
      {name: 'paymentRedirect', path: 'paymentRedirect/:code/:hash/:gateway', component: () => import('pages/PaymentRedirect.vue')},
      {name: 'paymentResult', path: 'payment/result', component: () => import('pages/PaymentResult.vue')}
    ]
  },
  {
    path: '/aboutUs',
    redirect: '/about-us'
  },
  {
    path: '/404',
    component: () => import('pages/Error404.vue'),
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
