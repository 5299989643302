import {GetterTree} from 'vuex'
import {StateInterface} from './state'

function getJsFunction(model: any, propertyName: string) {
  if (model[propertyName] == null || model[propertyName].trim().length <= 0) {
    return null
  }
  try {
    const fn = eval(model[propertyName])
    if (typeof fn !== 'function') return null
    return fn
  } catch {
    return null
  }
}

function prepareJsFunctions(model: any) {
  model.inputMapperFn = getJsFunction(model, 'jsInputMapper')
  model.outputMapperFn = getJsFunction(model, 'jsOutputMapper')
  model.controllerFn = getJsFunction(model, 'jsController')
}

const getters: GetterTree<StateInterface, StateInterface> = {
  hasPermission(state) {
    return (permissionName: string) => {
      const parts = permissionName && permissionName.toLowerCase().split('.') as string[]
      if (!parts) return false
      if (parts.length == 1) return state.permissions[parts[0] as any]
      if (parts.length == 2) return state.permissions[parts[0] as any]
        && state.permissions[parts[0] as any][parts[1] as any]
      if (parts.length == 3) return state.permissions[parts[0] as any]
        && state.permissions[parts[0] as any][parts[1] as any]
        && state.permissions[parts[0] as any][parts[1] as any].indexOf(parts[2]) >= 0
      return false
    }
  },
  getTenantInfo(state) {
    return () => {
      return state.tenant
    }
  },
  getUserCredit(state) {
    return () => {
      return state.userCredit
    }
  },
  getUserClaims(state) {
    return () => {
      return state.user
    }
  },
  getChatProps(state) {
    return () => {
      return state.chatProps
    }
  }
}

export default getters
