import {ActionTree} from 'vuex'
import {StateInterface} from './state'
import {api} from 'boot/api'
import {SetBearerToken} from "boot/axios";
import axios from "axios";
import {LocalStorage, useMeta} from "quasar";

const actions: ActionTree<StateInterface, StateInterface> = {
  //#region "General"
  async clearPreFetch(ctx) {
    ctx.commit('clearPreFetch')
  },
  //#endregion "General"

  //#region "Core"
  async loadApplicationTerms(ctx) {
    const res = await axios.get(`${process.env.API_URL}/core/application/terms`)
    if (res.data?.success)
      ctx.commit('preFetch/terms', res.data?.result)
  },
  //#endregion "Core"

  //#region "Identity"
  async login(ctx, {username, password, verificationCode}) {
    try {
      let res = null
      if (password) {
        res = await api.identity.account.login(username, password);
      } else {
        res = await api.identity.account.loginByVerificationCode(username, verificationCode);
      }

      if (res && res.data.success) {
        SetBearerToken(res.data.result.accessToken)
        LocalStorage.set('accessToken', res.data.result.accessToken)
        LocalStorage.set('refreshToken', res.data.result.refreshToken)
        LocalStorage.set('userInfo', JSON.stringify(res.data.result.claims))
        ctx.commit('setUser', res.data.result.claims)
        ctx.commit('setPermissions', res.data.result.permissions)
        await ctx.dispatch('loadTenantInfo')
        await ctx.dispatch('getUserCredit')
        return {success: true, error: null}
      } else {
        return {success: false, error: res.data.error}
      }
    } catch (e: any) {
      return {success: false, error: e.response.message}
    }
  },
  async loginByHash(ctx, hashToken) {
    try {
      const res = await api.identity.account.loginByHash(hashToken);
      if (res.data.success) {
        SetBearerToken(res.data.result.accessToken)
        localStorage.setItem('accessToken', res.data.result.accessToken)
        localStorage.setItem('refreshToken', res.data.result.refreshToken)
        localStorage.setItem('userInfo', JSON.stringify(res.data.result.claims))
        ctx.commit('setUser', res.data.result.claims)
        ctx.commit('setPermissions', res.data.result.permissions)
        await ctx.dispatch('loadTenantInfo')
        await ctx.dispatch('getUserCredit')
        return {success: true, error: null}
      } else {
        return {success: false, error: res.data.error}
      }
    } catch (e: any) {
      return {success: false, error: e.response.message}
    }
  },
  async tryLogin(ctx) {
    try {
      const accessToken = LocalStorage.getItem('accessToken')
      if (accessToken == null) return false
      SetBearerToken(accessToken)
      if (!ctx.state.user) {
        let claims = await axios.get('user/info', {baseURL: process.env.API_URL + '/identity'})
        ctx.commit('setUser', claims.data.result.claims)
        ctx.commit('setPermissions', claims.data.result.permissions)
        LocalStorage.set('userInfo', JSON.stringify(claims.data.result.claims))
      }
      if (!ctx.state.tenant)
        await ctx.dispatch('loadTenantInfo')
      if (!ctx.state.userCredit)
        await ctx.dispatch('getUserCredit')
      return true
    } catch (e) {
      return false
    }
  },
  async tryRefreshToken(ctx) {
    try {
      console.log('LocalStorage.getItem(\'refreshToken\')', LocalStorage.getItem('refreshToken'))
      const res = await axios.post('account/token', {
        refreshToken: LocalStorage.getItem('refreshToken'),
        type: 3,
        userType: 1,
        // @ts-ignore
      }, {baseURL: process.env.API_URL + '/identity', noNotify: true})
      LocalStorage.set('accessToken', res.data.result.accessToken)
      LocalStorage.set('refreshToken', res.data.result.refreshToken)
      SetBearerToken(res.data.result.accessToken)
      return true
    } catch (e) {
      await ctx.dispatch('logout')
      return false
    }
  },
  async logout(ctx) {
    LocalStorage.clear()
    ctx.commit('logout')
    return true
  },
  async loadTenantInfo(ctx) {
    const res = await axios.get(`${process.env.API_URL}/Tenant/Organization/InfoByUrl`)
    if (res.data?.success && res.data?.result) {
      LocalStorage.set('tenantInfo', res.data?.result)
      ctx.commit('setTenantInfo', res.data?.result)
    }
  },
  async getUserCredit(ctx) {
    const res = await api.tenant.transaction.credit()
    if (res.data?.success) {
      ctx.commit('setUserCredit', res.data?.result)
    }
  },
  load: async function (ctx) {
    await ctx.dispatch('loadTenantInfo')
  },
  //#endregion "Identity"

  //#region "Blog"
  async loadBlogCategories(ctx, params) {
    try {
      const res = await axios.get(`${process.env.API_URL}/Cms/ContentGroup/all`, {});
      if (res.data?.success)
        ctx.commit('preFetch/blogCategories', res.data?.result)
    } catch (e) {
      console.log('BLOG Error:', e)
    }
  },
  async loadBlog(ctx, params) {
    try {
      const res = await axios.get(`${process.env.API_URL}/Cms/Content/blog`, {params});
      if (res.data?.success)
        ctx.commit('preFetch/blog', res.data?.result)
    } catch (e) {
      console.log('BLOG Error:', e)
    }
  },
  async loadPost(ctx, id) {
    const res = await axios.get(`${process.env.API_URL}/Cms/Content/info?id=${id}`);
    if (res.data?.success)
      ctx.commit('preFetch/post', res.data?.result)
  },
  async getMeta(ctx, params) {
    const res = await axios.get(`${process.env.API_URL}/Cms/Page/meta`, {params})
    if (res.data?.success)
      ctx.commit('preFetch/pageMeta', res.data?.result)
  },
  async loadFaq(ctx) {
    try {
      // const res = await axios.get(`${process.env.API_URL}/Cms/Content/blog`, {params});
      // if (res.data?.success)
      let faq = [
              {
                question: 'چگونه حامی شوم؟',
                answer: 'شما می‌توانید با جستجو در پویش‌ها پویش مورد نظر خود را انتخاب نموده و جهت حمایت اقدام نمایید.'
              },
              {
                question: 'چگونه به عنوان موسسه نیکوکاری در حامیانه فعالیت کنم؟',
                answer: 'ما در حامیانه بستر مناسبی برای حامیان فراهم نموده‌ایم، برای بهره‌مندی از امکانات این سایت کافی است با ما تماس بگیرید.'
              },
              {
                question: 'چگونه پویش ثبت کنم؟',
                answer: 'پویش‌ها فقط از طرف موسسات معتبر که از طرف حامیانه احراز هویت شده‌اند، قابل تعریف هستند.'
              },
              {
                question: 'چگونه به حامیانه اعتماد کنم؟',
                answer: 'حامیانه ضمن اخذ مجوزهای لازم، این بستر را برای شما فراهم کرده است که گزارش‌های مرتبط با پویش‌های مورد نظر خود را مشاهده کنید.'
              }
            ]
        ctx.commit('preFetch/faq', faq)
    } catch (e) {
      console.log('FAQ Error:', e)
    }
  },
  //#endregion "Blog"

  //#region "SEO"
  setMeta(ctx, meta) {
    let newMeta = JSON.parse(JSON.stringify(ctx.state.meta))
    if (meta.title) {
      newMeta.title = meta.title
      newMeta.ogTitle = {
        name: 'og:title', content: meta.title
      }
    }
    if (meta.description) {
      newMeta.meta.description = {
        name: 'description',
        content: meta.description
      }
      newMeta.meta.ogDescription = {
        name: "og:description",
        content: meta.description
      }
    }
    if (meta.keywords) {
      newMeta.meta.keywords = {
        name: "keywords",
        content: meta.keywords
      }
    }
    if (meta.script) {
      newMeta.script = meta.script
    }
    if (meta.link) {
      newMeta.link = meta.link
    }
    ctx.commit('meta', newMeta)
  },
  async setDefaultMeta(ctx) {
    let meta = {
      title: 'حامیانه | پلتفرم جامع نیکوکاری',
      description: 'حامیانه بستر نیکوکاری بر محور شفافیت در امور موسسات نیکوکاری و خیریه‌ها می‌باشد. در حامیانه فرایندهای مددکاری، خیرات، اشتغال‌زایی و کمک به نیازمندان را به‌صورت آنلاین انجام دهید.',
      keywords: 'نیکوکاری، خیریه، مددکاری، نیازمندان، مسئولیت اجتماعی',
    }
    return await ctx.dispatch('setMeta', meta)
  },
  //#endregion "SEO"

  //#region "Charity"
  async loadLatestDonationAndReport(ctx) {
    const res = await axios.get(`${process.env.API_URL}/charity/charity/latestDonationAndReport`)
    if (res.data?.success)
      ctx.commit('preFetch/latestDonationAndReport', res.data?.result)
  },
  async loadBanners(ctx) {
    const res = await axios.get(`${process.env.API_URL}/tenant/Banner/list`)
    if (res.data?.success)
      ctx.commit('preFetch/banners', res.data?.result)
  },
  async loadCategories(ctx) {
    const res = await axios.get(`${process.env.API_URL}/Charity/ProjectGroup/all`)
    if (res.data?.success)
      ctx.commit('preFetch/categories', res.data?.result)
  },
  async loadCampaigns(ctx, params) {
    const res = await axios.get(`${process.env.API_URL}/Charity/Project/list`, {params});
    if (res.data?.success)
      ctx.commit('preFetch/campaigns', res.data?.result)
  },
  async loadProjectTopList(ctx) {
    const res = await axios.get(`${process.env.API_URL}/charity/project/projectTopList`)
    if (res.data?.success)
      ctx.commit('preFetch/projectTopList', res.data?.result)
  },
  async loadAchievements(ctx, params) {
    const res = await axios.get(`${process.env.API_URL}/tenant/Report/name`, {params})
    if (res.data?.result)
      ctx.commit('preFetch/achievements', res.data?.result)
  },
  async loadCampaign(ctx, id) {
    const res = await axios.get(`${process.env.API_URL}/charity/Project/details/${id}`)
    if (res.data?.success)
      ctx.commit('preFetch/campaign', res.data?.result)
  },
  async loadDonationDetails(ctx, id) {
    const res = await axios.get(`${process.env.API_URL}/charity/Project/${id}`)
    if (res.data?.success)
      ctx.commit('preFetch/donation', res.data?.result)
  },
  async loadReports(ctx, params) {
    const res = await axios.get(`${process.env.API_URL}/charity/report/list`, {params})
    if (res.data?.success)
      ctx.commit('preFetch/reports', res.data?.result)
  },
  async loadReport(ctx, id) {
    const res = await axios.get(`${process.env.API_URL}/charity/report/info?id=${id}`);
    if (res.data?.success)
      ctx.commit('preFetch/report', res.data?.result)
  },
  async loadProjectSupportPacks(ctx, id) {
    const res = await axios.get(`${process.env.API_URL}/charity/Project/supportPacks/${id}`)
    if (res.data?.success)
      ctx.commit('preFetch/projectSupportPacks', res.data?.result)
  },
  async loadCharities(ctx) {
    const res = await axios.get(`${process.env.API_URL}/charity/charity/list`)
    if (res.data?.success)
      ctx.commit('preFetch/charities', res.data?.result)
  },
  async loadCharity(ctx, name) {
    const res = await axios.get(`${process.env.API_URL}/Charity/Charity/info/${name}`)
    if (res.data?.success)
      ctx.commit('preFetch/charity', res.data?.result)
  },
  async loadFamilies(ctx, params) {
    if (ctx.state.preFetch.reports[params.index]?.families == null) {
      const res = await axios.get(`${process.env.API_URL}/charity/SupportPackFamily/families?supportPackId=${params.supportPackId}`)
      if (res.data?.success)
        ctx.commit('preFetch/families', {index: params.index, families: res.data?.result})
    }
  },
  async loadGiftGroupList(ctx) {
    const res = await axios.get(`${process.env.API_URL}/charity/GiftGroup/list`)
    if (res.data?.success)
      ctx.commit('preFetch/giftGroupList', res.data?.result)
  },
  async loadGiftList(ctx, params) {
    const res = await axios.get(`${process.env.API_URL}/charity/Gift/list`,{params})
    if (res.data?.success)
      ctx.commit('preFetch/giftList', res.data?.result)
  },
  //#endregion "Charity"
}

export default actions
