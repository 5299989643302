import {store} from 'quasar/wrappers'
import {InjectionKey} from 'vue'
import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import {createStore, Store as VuexStore, useStore as vuexUseStore,} from 'vuex'

// import example from './module-example'
// import { ExampleStateInterface } from './module-example/state';

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export interface StateInterface {
  // Define your own store structure, using submodules if needed
  // example: ExampleStateInterface;
  // Declared as unknown to avoid linting issue. Best to strongly type as per the line above.
}

// provide typings for `this.$store`
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $store: VuexStore<StateInterface>
  }
}

// provide typings for `useStore` helper
export const storeKey: InjectionKey<VuexStore<StateInterface>> = Symbol('vuex-key')

export default store(function (/* { ssrContext } */) {
  return createStore<StateInterface>({
    modules: {
      // example
    },
    state: () =>  state,
    actions: actions as any,
    mutations: mutations as any,
    getters: getters as any,
    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
  });
})

export function useStore() {
  return vuexUseStore()
  //storeKey
}
