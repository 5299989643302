import {ITenantInfo, IUserClaims} from 'boot/api'

export interface StateInterface {
  user: IUserClaims | null;
  userCredit: number | null;
  tenant: ITenantInfo | null;
  isPageLoaded:boolean;
  permissions: string[];
  chatProps: Object | null;
  preFetch: any;
  meta: any;
}

const state: StateInterface = {
  user: null,
  userCredit: null,
  tenant: null,
  isPageLoaded: false,
  permissions: [],
  chatProps: {},
  preFetch: {},
  meta: {
    title: 'حامیانه',
    meta: {
      description: {name: 'description', content: 'سامانه ابری حامیانه'},
      keywords: {name: 'keywords', content: 'نیکوکاری، نیازمند، کمک به همنوع'},
      equiv: {'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8'},
      ogTitle: {
        name: 'og:title', content: 'حامیانه'
      }
    }
  }
}

export default state
