import {boot} from 'quasar/wrappers'
import * as moment from 'jalali-moment'
import NumToText from "boot/NumToText"
import * as Sentry from "@sentry/vue";

declare global {
  interface String {
    toJalaliDateTime(): string;

    toJalaliDate(): string;

    toJalaliFullDate(): string;

    getJalaliMonth(): string;

    toGregorianDateTime(): string;

    toGregorianDate(): string;

    getTime(): string;

    getDate(): string;

    toNumber(): string;

    toMoney(suffix: string): string;

    toEnglishNumber(): string;

    isValidNationalNumber(): boolean;

    isPersianString(): boolean;

    isEnglishString(): boolean;

    isNumberString(): boolean;

    isValidEmail(): boolean;

    isValidPhoneNumber(): boolean;

    htmlToText(): string;

    getElapsedTime(): string;

    getResizedImageUrl(width: number, height: number): string;
  }

  interface Number {
    minuteToTime(): string;

    toNumber(): string;

    toMoney(suffix: string): string;

    numToTextSimple(): string;
  }
}

String.prototype.toNumber = function () {
  if (this != null) {
    return this.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return this
  }
}

Number.prototype.toNumber = function () {
  if (this != null) {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return this
  }
}

String.prototype.toMoney = function (suffix: string = '﷼') {
  return `${this.toNumber()} ${suffix}`.trimEnd()
}

String.prototype.toEnglishNumber = function () {
  if (this == null)
    return '';
  const englishNumbers = "0123456789";
  const persianNumbers1 = "٠١٢٣۴۵۶٧٨٩";
  const persianNumbers2 = "۰۱۲۳۴۵۶۷۸۹";
  const arabicNumbers = "٠١٢٣٤٥٦٧٨٩";
  let res = ''
  for (let c of this) {
    let index1 = persianNumbers1.indexOf(c)
    let index2 = persianNumbers2.indexOf(c)
    let index3 = arabicNumbers.indexOf(c)
    let index = (index1 >= 0 ? index1 : index2 >= 0 ? index2 : index3)
    res += (index >= 0 ? englishNumbers[index] : c);
  }

  return res.toString()
}

Number.prototype.toMoney = function (suffix: string = '﷼') {
  return `${this.toNumber()} ${suffix}`.trimEnd()
}

Number.prototype.minuteToTime = function () {
  if (this == null) {
    return ""
  }
  let hour = Math.floor(Number(this) / 60)
  let minute = Number(this) % 60
  let hourString = hour < 10 ? '0' + hour.toString() : hour.toString()
  let minuteString = minute < 10 ? '0' + minute.toString() : minute.toString()
  return hourString + ':' + minuteString
};

Number.prototype.numToTextSimple = function () {
  let num = Number(this) / 10
  if (num < 1000000) {
    num /= 1000
    return num + ' هزار تومان'
  } else {
    num = parseInt((num / 100000).toFixed(0))
    let floatingPointDigit = num % 10
    num = Math.floor(num / 10)
    if (floatingPointDigit === 0)
      return num.toFixed(0) + ' میلیون تومان'
    else return num + ',' + floatingPointDigit + ' میلیون تومان'
  }
};

String.prototype.toJalaliDateTime = function () {
  if (this != null && moment.from(String(this), 'en').isValid()) {
    return moment.from(String(this), 'en', 'YYYY-MM-DD HH:mm:ss').clone().format('jYYYY/jMM/jDD HH:mm:ss')
  } else {
    return String(this)
  }
};

String.prototype.toJalaliDate = function () {
  if (this != null && moment.from(String(this), 'en').isValid()) {
    return moment.from(String(this), 'en', 'YYYY-MM-DD HH:mm:ss').clone().format('jYYYY/jMM/jDD')
  } else {
    return String(this)
  }
};

String.prototype.toJalaliFullDate = function () {
  if (this != null && moment.from(String(this), 'en').isValid()) {
    let res = moment.from(String(this), 'en', 'YYYY-MM-DD HH:mm:ss').clone().format('dddd jDD jMMMM jYYYY')
    res = res.replace('Saturday', 'شنبه')
    res = res.replace('Sunday', 'یکشنبه')
    res = res.replace('Monday', 'دوشنبه')
    res = res.replace('Tuesday', 'سه‌شنبه')
    res = res.replace('Wednesday', 'چهارشنبه')
    res = res.replace('Thursday', 'پنجشنبه')
    res = res.replace('Friday', 'جمعه')
    res = res.replace('Farvardin', 'فروردین')
    res = res.replace('Ordibehesht', 'اردیبهشت')
    res = res.replace('Khordaad', 'خرداد')
    res = res.replace('Tir', 'تیر')
    res = res.replace('Mordaad', 'مرداد')
    res = res.replace('Shahrivar', 'شهریور')
    res = res.replace('Mehr', 'مهر')
    res = res.replace('Aabaan', 'آبان')
    res = res.replace('Aazar', 'آذر')
    res = res.replace('Dey', 'دی')
    res = res.replace('Bahman', 'بهمن')
    res = res.replace('Esfand', 'اسفند')
    return res
  } else {
    return String(this)
  }
};

String.prototype.getJalaliMonth = function () {
  if (this != null && moment.from(String(this), 'en').isValid()) {
    let res = moment.from(String(this), 'en', 'YYYY-MM-DD HH:mm:ss').clone().format('jMMMM')
    res = res.replace('Farvardin', 'فروردین')
    res = res.replace('Ordibehesht', 'اردیبهشت')
    res = res.replace('Khordaad', 'خرداد')
    res = res.replace('Tir', 'تیر')
    res = res.replace('Mordaad', 'مرداد')
    res = res.replace('Shahrivar', 'شهریور')
    res = res.replace('Mehr', 'مهر')
    res = res.replace('Aabaan', 'آبان')
    res = res.replace('Aazar', 'آذر')
    res = res.replace('Dey', 'دی')
    res = res.replace('Bahman', 'بهمن')
    res = res.replace('Esfand', 'اسفند')
    return res
  } else {
    return String(this)
  }
};

String.prototype.getTime = function () {
  if (this != null && moment.from(String(this), 'en').isValid()) {
    return moment.from(String(this), 'en', 'YYYY-MM-DD HH:mm:ss').clone().format('HH:mm')
  } else {
    return String(this)
  }
};

String.prototype.getDate = function () {
  if (this != null && moment.from(String(this), 'en').isValid()) {
    return moment.from(String(this), 'en', 'YYYY-MM-DD HH:mm:ss').clone().format('YYYY-MM-DD')
  } else {
    return String(this)
  }
};

String.prototype.toGregorianDateTime = function () {
  if (this != null && moment.from(String(this), 'fa').isValid()) {
    return moment.from(String(this), 'fa', 'jYYYY-jMM-jDD HH:mm:ss').clone().format('YYYY-MM-DD HH:mm:ss')
  } else return String(this)
};

String.prototype.toGregorianDate = function () {
  if (this != null && moment.from(String(this), 'fa').isValid()) {
    return moment.from(String(this), 'fa', 'jYYYY-jMM-jDD HH:mm:ss').clone().format('YYYY-MM-DD')
  } else return String(this)
};

String.prototype.isValidNationalNumber = function () {
  if (this.length === 10) {
    if (this === '1111111111' || this === '0000000000' || this === '2222222222' || this === '3333333333' || this === '4444444444' || this === '5555555555' || this === '6666666666' || this === '7777777777' || this === '8888888888' || this === '9999999999') {
      return false;
    }
    let c = parseInt(this.charAt(9));
    let n = parseInt(this.charAt(0)) * 10 + parseInt(this.charAt(1)) * 9 + parseInt(this.charAt(2)) * 8 + parseInt(this.charAt(3)) * 7 + parseInt(this.charAt(4)) * 6 + parseInt(this.charAt(5)) * 5 + parseInt(this.charAt(6)) * 4 + parseInt(this.charAt(7)) * 3 + parseInt(this.charAt(8)) * 2;
    let r = n - parseInt((n / 11).toString()) * 11;
    return (r === 0 && r === c) || (r === 1 && c === 1) || (r > 1 && c === 11 - r);
  } else {
    return false;
  }
};

String.prototype.isPersianString = function () {
  return RegExp(/^[\u0600-\u06FF\s]+$/).test(String(this))
};

String.prototype.isEnglishString = function () {
  return RegExp(/^[A-Za-z][A-Za-z0-9]*$/).test(String(this))
};

String.prototype.isNumberString = function () {
  return RegExp(/^[0-9]*$/).test(String(this))
};

String.prototype.isValidEmail = function () {
  return RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(String(this))
};

String.prototype.isValidPhoneNumber = function () {
  var str = String(this).toEnglishNumber();
  return RegExp(/^(\+98|0)?9\d{9}$/).test(str)
};

String.prototype.htmlToText = function () {
  return String(this).replace(/<[^>]+>/g, '')
};

String.prototype.getElapsedTime = function () {
  // @ts-ignore
  let now = moment()//.startOf('day');
  let date = moment.from(String(this), 'en')

  let durationSeconds = now.diff(date, 'seconds')
  if (durationSeconds < 60) {
    return durationSeconds + ' ثانیه پیش'
  }

  let durationMinutes = now.diff(date, 'minutes')
  if (durationMinutes < 60) {
    return durationMinutes + ' دقیقه پیش'
  }

  let durationHours = now.diff(date, 'hours')
  if (durationHours < 24) {
    return durationHours + ' ساعت پیش'
  }

  let durationDays = now.diff(date, 'days')
  if (durationDays < 7) {
    return durationDays + ' روز پیش'
  }

  let durationWeeks = now.diff(date, 'weeks')
  if (durationWeeks < 4) {
    return durationWeeks + ' هفته پیش'
  }

  let durationMonths = now.diff(date, 'months')
  if (durationMonths < 12) {
    return durationMonths + ' ماه پیش'
  }

  let durationYears = now.diff(date, 'years')
  if (durationYears > 0) {
    return durationYears + ' سال پیش'
  }

  return '';
};

String.prototype.getResizedImageUrl = function (width: number, height: number) {
  let parts = this.split('.')
  let extension = parts.reverse()[0]
  parts.pop()
  return `${parts.join('.')}-${width}x${height}.${extension}`
};

export default boot(async ({store, app, router}) => {
  Sentry.init({
    app,
    release: 'main',
    dsn: "https://cd18a422b7dc4088891aac53710bbc7f@o1099902.ingest.sentry.io/4505165922369536",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  app.config.globalProperties.$hamianeTenantId = process.env.HAMIANE_TENANT_ID
  await store.dispatch('load')
})
