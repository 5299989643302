import {axios, AxiosInstance, SetBearerToken} from './axios'
import {boot} from "quasar/wrappers";

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $api: Api;
  }
}

//#region Global Interfaces
interface OperationResult<T> {
  success: boolean
  error: any,
  result: T
}

interface ILoginResult {
  accessToken: string
  refreshToken: string
  tenants: Array<any>
  claims: Array<any>
  permissions: any
}

export interface IUserClaims {
  amr: string;
  aud: string;
  auth_time: string;
  branch_id: string;
  client_id: string;
  email: string;
  email_verified: string;
  exp: string;
  family_name: string;
  given_name: string;
  iat: string;
  idp: string;
  iss: string;
  nbf: string;
  phone_number: string;
  phone_number_verified: string;
  role: string;
  scope: string[];
  sub: string;
  tenant_id: string;
  tenant_url: string;
  username: string;
}

export interface ITenantInfo {
  id: number;
  name: string;
  about: string;
  title: string;
  officialTitle: string;
  logo: string;
  applicationId: string;
  applicationName: string;
  applicationTitle: string;
  details: Array<ITenantDetail>;
}

export interface ITenantDetail {
  key: string;
  value: string;
}

export interface IUserTransaction {
  descriptions: string;
  date: string;
  typeLookupTitle: string;
  amount: number;
}

//#endregion Global Interfaces


//#region Identity
class Account {
  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  loginByHash = (hashToken: string) => {
    return axios.post<OperationResult<ILoginResult>>('account/token', {
      type: 0,
      userType: 0,
      hash: hashToken
    }, {baseURL: this.baseUrl, noNotify: true} as any)
  }

  login = (username: string, password: string) => {
    return axios.post<OperationResult<ILoginResult>>('account/token', {
      username,
      password,
      type: 1,
      userType: 1
    }, {baseURL: this.baseUrl, noNotify: true} as any)
  }
  loginByVerificationCode = (username: string, verificationCode: string) => {
    return axios.post<OperationResult<ILoginResult>>('account/token', {
      username,
      verificationCode,
      type: 2,
      userType: 1
    }, {baseURL: this.baseUrl, noNotify: true} as any)
  }
  sendCode = (username: string) => {
    return axios.post<OperationResult<ILoginResult>>('account/sendCode/' + username, null, {
      baseURL: this.baseUrl,
      noNotify: true
    } as any)
  }
  checkUser = (username: string) => {
    return axios.post<OperationResult<ILoginResult>>('account/check/' + username, null, {
      baseURL: this.baseUrl,
      noNotify: true
    } as any)
  }
  register = (username: string, password: string, firstName: string, lastName: string) => {
    return axios.post<OperationResult<ILoginResult>>('account/register', {
      username,
      password,
      firstName,
      lastName
    }, {baseURL: this.baseUrl, noNotify: true} as any)
  }
  registerConfirm = (username: string, code: string) => {
    return axios.post<OperationResult<ILoginResult>>('account/register/confirm', {
      username,
      code
    }, {baseURL: this.baseUrl, successMessage: 'ثبت نام با موفقیت انجام شد'} as any)
  }
}

export class IdentityApi {
  baseUrl: string
  account: Account

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.account = new Account(baseUrl)
  }
}

//#endregion Identity

//#region Tenant
class Transaction {
  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  credit = () => {
    return axios.get<OperationResult<number>>(`transaction/credit`,
      {baseURL: this.baseUrl, noNotify: true} as any)
  }
  turnover = () => {
    return axios.get<OperationResult<Array<IUserTransaction>>>(`transaction/turnover`,
      {baseURL: this.baseUrl, noNotify: true} as any)
  }

}

export class TenantApi {
  baseUrl: string
  transaction: Transaction

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.transaction = new Transaction(baseUrl)
  }
}

//#endregion Transaction

export class Api {
  identity = new IdentityApi(process.env.API_URL + '/identity' ?? '');
  tenant = new TenantApi(process.env.API_URL + '/tenant' ?? '');
}

export const api = new Api()
export default boot(({app}) => {
  app.config.globalProperties.$api = api
})
