import {boot} from 'quasar/wrappers';
import axios, {AxiosInstance} from 'axios';
import {LocalStorage, Notify} from 'quasar'
import * as Vue from 'vue'
import {Platform} from 'quasar'
import {version} from '../../package.json'
import * as moment from 'jalali-moment'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}


//#region Token Handling

export function SetBearerToken(token: any) {
  axios.defaults.headers['Authorization'] = `Bearer ${token}`
}

SetBearerToken(LocalStorage.getItem('accessToken'))
//#endregion Token Handling

Notify.setDefaults({
  position: 'bottom',
  timeout: 2500,
  textColor: 'white',
  actions: [{icon: 'la la-close', color: 'white'}]
})

export {axios, AxiosInstance}
let axiosInterceptor: number | null = null; // outer variable
export default boot(({app, store, router, ssrContext, publicPath, urlPath, redirect}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  app.config.globalProperties.$axios = axios
  // remove interceptor if exists
  // I found axiosInterceptor starts with 0, then +1.
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.request.eject(axiosInterceptor);
  }

  axiosInterceptor = axios.interceptors.request.use(async (config: any) => {

    if (ssrContext) {
      config.headers = {}
      config.headers['user-agent'] = ssrContext.req.headers["user-agent"]
      config.headers['saanaa-ip-address'] = (ssrContext.req.headers['x-forwarded-for'])?.toString().split(',')[0] || ssrContext?.req.connection.remoteAddress
      config.headers['Saanaa-Origin'] = ssrContext.req.headers.host
    } else {
      if (!process.env.SERVER && Platform.is.cordova) {
        config.headers['Saanaa-Origin'] = "https://hamiane.com"
      }
    }


    // console.log('our ENV SERVER',process.env.SERVER)


    return config
  }, async err => {
    await console.log(err?.response?.status,err?.response?.statusText,err?.response?.config?.url)
    return err
  })

  axios.interceptors.response.use((res: any) => {
    if (res.config && !res.config.noNotify && res.config.method != 'get') {
      Notify.create({
        type: 'positive',
        message: res.config.successMessage || 'پاسخ دریافت شد!'
      })
    }
    return res;
  }, async err => {
    console.log('=================', err)
    if (err && err.response && err.response.status === 401) {
      const tryCount = err.response?.config?.tryCount || 0
      if (tryCount < 2 && await store.dispatch('tryRefreshToken')) {
        err.response.config.headers['Authorization'] = 'Bearer ' + LocalStorage.getItem('accessToken')
        err.response.config.tryCount = tryCount + 1;
        return axios(err.response.config);
      }
    } else {
      if (process.env.PROD && err.response.config.url != `${process.env.API_URL}/Notify/message/send`) {
        try {
          let img = process.env.SERVER? null : await app.config.globalProperties.$screenshot()
          let data = new FormData()
          let text = `<b>Browser: </b>${app.config.globalProperties.$q.platform.is.name} ${app.config.globalProperties.$q.platform.is.version}\n`
          text += `<b>OS: </b>${app.config.globalProperties.$q.platform.is.platform}\n`
          text += `<b>AppVersion: </b>${version}\n`
          store.state.user ? text += `<b>User: </b>${store.state.user.given_name} ${store.state.user.family_name}\nUserId: ${store.state.user.sub}\nUserName: ${store.state.user.username}\n` : undefined;
          store.state.tenant ? text += `<b>Tenant: </b>${store.state.tenant.title}\n` : undefined
          text += `<b>Current Url: </b>${location.href}\n`
          text += `<b>Error Code: </b>${err.response.status}\n`
          text += `<b>Message: </b>${err.response.statusText}\n`
          err?.response?.data?.error ? text += `<b>Error: </b>${JSON.stringify(err.response.data.error)}\n` : undefined
          text += `<b>EndPoint: </b>${err.response.config.baseURL ? err.response.config.baseURL + '/' : ''}${err.response.config.url}\n`
          err.response.config.params ? text += `<b>Params: </b>${decodeURIComponent(JSON.stringify(err.response.config.params) || "")}\n` : undefined
          err.response.config.data && err.response.config.data.trim().length>0 ? text += `<b>Body: </b>${decodeURIComponent(JSON.stringify(JSON.parse(err.response.config.data)) || "")}\n` : undefined
          // @ts-ignore
          text += moment().format('jYYYY/jMM/jDD')
          data.append('file', img)
          data.append('text', text)
          data.append('messageName', "ReportError")
          // @ts-ignore
          axios.post(`${process.env.API_URL}/Notify/message/send`, data, {noNotify: true}).then(res => {
            if (res.data.success)
              console.log('error sent')
          }).catch(() => {
            console.log('sending error failed')
          })
        }catch (e) {
          console.log(e)
        }

      }
    }
    if (!err.response?.config?.noNotify) {
      Notify.create({
        type: 'negative',
        message: err.response?.config?.errorMessage || 'درخواست با خطا مواجه شد!',
        caption: err.message
      })
    }
    return err
  });
})
