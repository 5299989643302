<template>
  <router-view/>
</template>

<script>
import {defineComponent} from 'vue';
import {setCssVar, useMeta} from "quasar";

export default defineComponent({
  name: 'App',
  data: () => ({
    link: null,
    themeName: 'hamiane', //For change of color theme // hamiane, orangeBlue, blueOrange, yellowBlue
  }),
  computed: {
    tenantInfo() {
      return this.$store.state.tenant
    }
  },
  methods: {
    onLaunch() {
      window.plugins.webintent.getUri((uri) => {
        if (uri !== null) {
          this.link = uri
          let index = uri.indexOf('com/')
          if (index > 0) {
            this.$router.push(uri.substring(index + 3))
          } else if (index < 0) {
            index = uri.indexOf('hamiane://')
            if (index >= 0) {
              this.$router.push(uri.substring(9))
            }
          }
        }
      });
      window.plugins.webintent.onNewIntent((uri) => {
        if (uri !== null && uri !== this.link) {
          this.link = uri
          let index = uri.indexOf('com/')
          if (index > 0) {
            this.$router.push(uri.substring(index + 3))
          } else if (index < 0) {
            index = uri.indexOf('hamiane://')
            if (index >= 0) {
              this.$router.push(uri.substring(9))
            }
          }
        }
      })
    },
    changeTheme(color) {
      let blue = "#1d4ed8"
      let blueDark = "#00377b"
      // let red = ""
      let yellow = "#fdac1d"
      let orange = "#f6861f"
      // let purple = ""
      switch(color) {
        case 'hamiane':
          setCssVar("primary","#f1185c")
          setCssVar("secondary","#00bcd4")
          break;
        case 'blueOrange':
          setCssVar("primary",blue)
          setCssVar("secondary", orange)
          break;
        case 'orangeBlue':
          setCssVar("primary",orange)
          setCssVar("secondary",blue)
          break;
        case 'yellowBlue':
          setCssVar("primary",yellow)
          setCssVar("secondary",blueDark)
          break;
      }
    },
  },
  mounted() {
    //this.changeTheme(this.themeName)
    if(window.location.href.includes("localhost:8081") || window.location.href.includes("hamiane.com"))
      this.changeTheme('hamiane');
    if(window.location.href.includes("localhost:8085") || window.location.href.includes("baharemehrbani.ir"))
      this.changeTheme('blueOrange');
    if(window.location.href.includes("localhost:8048") || window.location.href.includes("akbar-charity.ir"))
      this.changeTheme('orangeBlue');
    if(window.location.href.includes("localhost:8046") || window.location.href.includes("mizbanankhorshid.com"))
      this.changeTheme('orangeBlue');

    document.addEventListener('deviceready', this.onLaunch, false)
  },
  beforeCreate() {
    useMeta(this.$store.state.meta)
  }
})
</script>
