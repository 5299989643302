import {MutationTree} from 'vuex'
import {StateInterface} from './state'
import {ITenantInfo, IUserClaims} from "boot/api";

const mutation: MutationTree<StateInterface> = {
  //#region "General"
  clearPreFetch(state) {
    state.preFetch = {}
  },
  setPageLoadState(state, payload: boolean) {
    state.isPageLoaded = payload
  },
  //#endregion "General"

  //#region "Core"

  //#endregion "Core"

  //#region "Identity"
  setPermissions(state, payload: string[]) {
    state.permissions = payload
    // state.permissions.splice(0)
    // payload.forEach(x => state.permissions.push(x))
  },
  setTenantInfo(state, payload: ITenantInfo) {
    state.tenant = payload
  },
  setUserCredit(state, payload: number) {
    state.userCredit = payload
  },
  setUser(state, payload: IUserClaims) {
    state.user = payload
  },
  logout(state) {
    state.user = null
  },
  async 'preFetch/terms'(state, payload) {
    state.preFetch.terms = payload
  },
  //#endregion "Identity"

  //#region "Blog"
  'preFetch/blogCategories'(state, payload) {
    state.preFetch.blogCategories = payload
  },
  'preFetch/blog'(state, payload) {
    state.preFetch.blog = payload
  },
  'preFetch/post'(state, payload) {
    state.preFetch.post = payload
  },
  async 'preFetch/pageMeta'(state, payload) {
    state.preFetch.pageMeta = payload
  },
  'preFetch/faq'(state, payload) {
    state.preFetch.faq = payload
  },
  //#endregion "Blog"

  //#region "SEO"
  'meta/title'(state, payload) {
    state.meta.title = payload
  },
  'meta'(state, payload) {
    state.meta = Object.assign(state.meta, payload)
  },
  //#endregion "SEO"

  //#region "Charity"
  async 'preFetch/latestDonationAndReport'(state, payload) {
    state.preFetch.latestDonationAndReport = payload
  },
  async 'preFetch/banners'(state, payload) {
    state.preFetch.banners = payload
  },
  async 'preFetch/categories'(state, payload) {
    state.preFetch.categories = payload
  },
  async 'preFetch/projectTopList'(state, payload) {
    state.preFetch.projectTopList = payload
  },
  async 'preFetch/campaigns'(state, payload) {
    state.preFetch.campaigns = payload
  },
  async 'preFetch/achievements'(state, payload) {
    state.preFetch.achievements = payload
  },
  async 'preFetch/campaign'(state, payload) {
    state.preFetch.campaign = payload
  },
  async 'preFetch/donation'(state, payload) {
    state.preFetch.donation = payload
  },
  async 'preFetch/reports'(state, payload) {
    state.preFetch.reports = payload
  },
  async 'preFetch/report'(state, payload) {
    state.preFetch.report = payload
  },
  async 'preFetch/projectSupportPacks'(state, payload) {
    state.preFetch.projectSupportPacks = payload
  },
  async 'preFetch/charities'(state, payload) {
    state.preFetch.charities = payload
  },
  async 'preFetch/charity'(state, payload) {
    state.preFetch.charity = payload
  },
  async 'preFetch/families'(state, payload) {
    state.preFetch.reports[payload.index].families = payload.families
  },
  async 'preFetch/giftGroupList'(state, payload) {
    state.preFetch.giftGroupList = payload
  },
  async 'preFetch/giftList'(state, payload) {
    state.preFetch.giftList = payload
  },
  //#endregion "Charity"
}

export default mutation
