import {boot} from 'quasar/wrappers'
import * as htmlToImage from 'html-to-image';


async function screenshot() {
  try {
    return await htmlToImage.toBlob(document.body);
  } catch (e) {
    console.log('something went wrong with screenshot!', e);
    return null
  }
}

function openReport(reportName: string, query: { [key: string]: any; }) {
  let address = `${location.origin}/report/${reportName}`
  let queryParams = `?`
  for (let key in query) {
    queryParams += `&${key}=${query[key]}`
  }
  window.open(address + queryParams)
}



export default boot(async ({store, app}) => {
  app.config.globalProperties.$openReport = openReport
  app.config.globalProperties.$screenshot = screenshot
  await store.dispatch('load')
})
